import Vue from "vue";
import Router from "vue-router";
// import axios from "axios";
import store from "./store";
Vue.use(Router);
let router = new Router({
   mode: "history",
   routes: [
      {
         path: '/',
         component: () => import('./components/layouts/loginLayout'),
         meta: {
            requiresAuth: false,
         },
         children: [
            {
               path: '/',
               name: 'loginPage',
               component: () => import('./views/login/loginPage'),
                meta: {
                  requiresAuth: false,
               },
            },
            {
               path: '/forgotPassword',
               name: 'forgotPassword',
               component: () => import('./views/login/forgotPassword'),
                meta: {
                  requiresAuth: false,
               },
            },
            {
               path: '/Verification',
               name: 'otpVerification',
               component: () => import('./views/login/otpVerification'),
                meta: {
                  requiresAuth: false,
               },
            },
            {
               path: '/newPassword/:token',
               name: 'newPassword',
               component: () => import('./views/login/newPassword'),
                meta: {
                  requiresAuth: false,
               },
            },
         ],
      },
      {
         path: '/dashboard',
         component: () => import('./components/layouts/dashboardLayout'),
         meta: {
            requiresAuth: true,
         },
         children: [
            {
               path: '/',
               name: 'Dashboard',
               component: () => import('./views/homePage'),
               meta: {
                  requiresAuth: true,
               },
            },
            {
               path: '/users',
               name: 'Users',
               component: () => import('./views/allUsers'),
               meta: {
                  requiresAuth: true,
               },
            },
            {
               path: '/petitions',
               name: 'Petitions',
               component: () => import('./views/petitionsPage'),
               meta: {
                  requiresAuth: true,
               },
            },
            {
               path: '/viewBill',
               name: 'Bill details',
               component: () => import('./views/viewPetitonBill'),
               meta: {
                  requiresAuth: true,
               },
            },

            {
               path: '/leaderboard',
               name: 'Leaderboard',
               component: () => import('./views/leaderboardPage'),
               meta: {
                  requiresAuth: true,
               },
            },
            {
               path: '/feedbacks',
               name: 'Feedbacks',
               component: () => import('./views/feedbackPage'),
               meta: {
                  requiresAuth: true,
               },
            },

         ],
      },
      {
         path: '/viewUsers',
         name: 'viewUsers',
         component: () => import('./views/viewUsers'),
         meta: {
            requiresAuth: true,
         },
      },


   ],
   scrollBehavior() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
   },
});
router.beforeEach((to, from, next) => {
   localStorage.setItem("PRE", from.path);
   if (
      to.matched.some((route) => route.meta.requiresAuth === true) &&
      store.state.isLoggedIn == false
   ) {
      console.log("1");
      next({ name: "loginPage", params: { lastPage: from } });
      return;
   }
   if (to.name === "loginPage" && store.state.isLoggedIn == true) {
      console.log("redirect to dash");
      next({ name: "Dashboard" });
   }
   const resolved = router.resolve(to.path);
   if (!resolved.route.matched.length) {
     // If the path is invalid, redirect based on login status
     console.log("isloggedin",store.state.isLoggedIn);
     if (store.state.isLoggedIn) {
       console.log("Redirecting to dashboard for authenticated user");
       next({ name: "Dashboard" });
     } 
   //   else {
   //     console.log("Redirecting to login page for unauthenticated user");
   //     next({ name: "loginPage" });
   //   }
     return;
   }
   // if (to.name === "dashboard" && store.state.isLoggedIn == true) {
   //    console.log('2')
   //    next({ name: "dashboard" });
   // }
   next();
});

export default router;