import Vue from "vue";
import Vuex from "vuex";
import router from "./router";
import Cookies from 'js-cookie';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
   isLoggedIn: JSON.parse(localStorage.getItem("isLoggedIn")) || false,
    userData: {},
    snackbar: {
      visible: false,
      message: "",
      type: "success",
      duration: 2000,
    },
  },
  mutations: {
    setUserData(state, payload) {
      state.userData = payload;
      localStorage.setItem("role", payload.userName);
      localStorage.setItem("email", payload.email);
    },
    loginUser(state, payload) {
      state.isLoggedIn = true;
      state.userData = payload;
    localStorage.setItem("isLoggedIn", true); 
      const token = Cookies.get("token");
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
    },
    logoutUser(state) {
      state.isLoggedIn = false;
      state.userData = {};
      Cookies.remove("token");
      delete axios.defaults.headers.common['Authorization'];
    localStorage.clear();
    console.log("User logged out");
    router.push("/");
    },
    // loginUser(state) {
    //   state.isLoggedIn = true;
    //   localStorage.setItem("isLoggedIn", true); 
    // },
    // logoutUser(state) {
    //   state.isLoggedIn = false;
    //   state.userData = {};
    //   Cookies.remove("token");
    //   localStorage.clear();
    //   console.log("User logged out");
    //   router.push("/");
    // },
    showSnackbar(state, payload) {
      state.snackbar.visible = true;
      state.snackbar.message = payload.message;
      state.snackbar.type = payload.type || "success";
      state.snackbar.duration = payload.duration || 3000;
    },
    hideSnackbar(state) {
      state.snackbar.visible = false;
    },
  
  },
  actions: {
     setUserData({ commit }, userData) {
      commit("setUserData", userData);
    },
    logout({ commit }) {
      commit("logoutUser");
    },
    triggerSnackbar({ commit }, payload) {
      commit("showSnackbar", payload);
      setTimeout(() => {
        commit("hideSnackbar");
      }, payload.duration || 3000);
    },
  },
});
