// vuetify.js
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false, // Enable dark mode globally if needed
    themes: {
      light: {
        primary: colors.grey.base,  // Grey primary color for light mode
        textColor: '#000000',       // Black text color for light mode
      },
      dark: {
        primary: colors.grey.base,  // Grey primary color for dark mode
        textColor: '#FFFFFF',       // White text color for dark mode
      },
    },
  },
});
