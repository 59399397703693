import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router';
import './style.css';
import 'vuetify/dist/vuetify.min.css';
import axios from 'axios'
import Cookies from 'js-cookie';
import store from './store'
import VueElementLoading from 'vue-element-loading'
import ServerError from './common/errorPage'
import ImageLoader from './common/imageLoader'
import PageLoader from './common/pageLoader'


Vue.component('ServerError', ServerError)
Vue.component('ImageLoader', ImageLoader)
Vue.component('PageLoader', PageLoader)
Vue.component('VueElementLoading', VueElementLoading)
Vue.prototype.$axios = axios;
Vue.prototype.$cookies = Cookies;
window.axios = require('axios')
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';

// Set up Axios default token header if token exists in cookies
const token = Cookies.get("token");
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
// implement axios interceptor
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 406) {
      store.commit("logoutUser");
    }
    return Promise.reject(error);
  }
);
Vue.prototype.baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
