<template>
  <div>
    <v-app id="app">
       <Snackbar />
      <router-view style="min-height: 100vh" />
    </v-app>
  </div>
</template>
    <script>
import Snackbar from "@/components/AppSnackbar.vue";
export default {
  data() {
    return {};
  },
 components: {
    Snackbar,
  },
};
</script>
<style>
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: DM Sans !important;
   letter-spacing: 0px !important;
}
.full-height {
  height: 100%; 
  }
.v-application {
  min-height: 100vh;
}
</style>
