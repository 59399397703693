<!-- src/components/Snackbar.vue -->
<template>
   <v-snackbar right top  v-model="snackbar.visible"
    :timeout="2000"
    :color="snackbar.type">
     <!-- :timeout="snackbar.duration" -->
      <v-layout wrap justify-center>
        <v-flex xs11 text-left class="align-self-center">
          <span style="color: black">
            {{ snackbar.message }}
          </span>
        </v-flex>

        <v-flex xs1 text-right>
          <v-btn small :ripple="false" text @click="snackbar.visible = false">
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
</template>

<script>
export default {
  computed: {
    snackbar() {
      return this.$store.state.snackbar;
    },
  },
};
</script>
